.app{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
}
.app-sidebar{
  
    height: 100vh;
  
}
.app-main{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}