.job-overview{
    width: 100%;
    height: max-content;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
  
    padding: 24px;
    padding-bottom: 64px;


    
}

.job-overview .section-1{
    width: 35%;
    display: flex;
    flex-direction: column;
    
    gap: 24px;
}
.section-1 .job-status{
    width: 400px;
    height:max-content;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 24px;
}
.section-1 .job-info{
    width: 400px;
    min-height: 784px;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.job-overview .section-2{
    width: 50%;
    display: flex;
  
    flex-direction: column;
    gap: 24px;
}
.section-2 .job-description{
    width: 700px;
    min-height:733px ;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}
.section-2 .job-keywords{
    width: 700px;
    min-height:max-content ;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px; 
}

.job-status-remainder{
    width: 100%;
    height: max-content;
     display: flex;
     flex-direction: row;
     gap:20px;
     padding: 10px;
    background-color: #F5F5F5;
    border-radius: 4px;

}
.job-status-remainder p{
    font-size: 14px;
    color: #4D4D4D;
    font-weight: 700;
}

.job-info-data{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
 

}
.joboverview-status{
    color:white;

            width:max-content;
            display:flex;
            flex-direction:row;
            justify-content:center;
            align-items:center;
            gap:8px;cursor:pointer;
            padding:8px 10px;
            border: none;
            border-radius: 4px;
           
            height: 33px;
            font-weight: 700;
            font-size: 14px;
}
.data-field{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: max-content;
    justify-content: space-between;
    min-height: 40px;
    
}
.data-name{
width: 50%;
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
font-size: 14px;
color: #7A7A7A;
font-weight: 500;


}
.data-value{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center; 
    flex-wrap: wrap;
    font-size: 14px;
    color: #1F1F1F;
    font-weight: 600;
}








.job-keywords,.keyword-input-wrapper,.job-description,.job-info{
    padding: 24px;
    display: flex;
    flex-direction: column;
 
    gap: 40px;
 
}
.keywords-header,.jd-header,.job-info-header-1,.job-info-header-2,.job-info-header-3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
 
}
.jd-actions{
    display: flex;
    flex-direction: row;
   gap: 14px;
    align-items: center;
    width: max-content;
}
.keyword-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;
}
.keyword-input .keyword-input-field{
    width: 285px ;
    height: 37px;
}

.keywords-title ,.keywords-edit, .jd-title,.jd-actions div,.job-info-title,.job-info-edit{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.keywords-title div,.jd-title div,.job-info-title div{
    font-size: 16px;
    font-weight: 700;
    color: #1F1F1F;
}
.keywords-edit ,.job-info-edit{
    cursor: pointer;
}
.keywords-edit div,.jd-actions div,.job-info-edit div{
    font-size: 14px;
    font-weight: 700;
    color: #1890FF;
}
.add-jd .generateJd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    width: 100%;
 
   
}

.keywords-nodata, .add-jd{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:10px;
    border-radius: 4px;
    border: 1.5px dashed #D0D0D0;
    width: 100%;
    height: 100%;
    padding: 80px 160px;
}
.add-jd{
    justify-content: space-around;
}
.skip-ai{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}
.skip-ai p{

    color: #4D4D4D;
    font-size: 12px;
    font-weight: 400;
   
}
.skip-ai span{
color: #1890FF;
font-size: 14px;
font-weight: 700;
cursor: pointer;
}
.keywords-nodata img,.generateJd>img{
    width: 93px;
    height: 93px;
}
.keywords-nodata h3,.add-jd h3{
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: #1F1F1F;
}
.keywords-nodata p, .add-jd p{
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #4D4D4D;
}
.keywords-nodata button,.keyword-input button{
    width: 148px;
    height: 37px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    background: #1890FF;
    border-radius: 4px;
    cursor: pointer;
}


.keywords-list{
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: max-content;
}
.keywords-list div{
    background: #F5F5F5;
    padding:  8px 16px;
    width: max-content;
    color: #1F1F1F;
    font-weight: 600;
    font-size: 14px;
    border-radius: 999px;
}
.keyword-modal-done{
    min-width: 82px;
    width: max-content;
    height: 37px;
    border-radius: 4px;
    padding: 10px 24px;
    border: 1.4px solid #4D4D4D;
    background: white;
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.keyword-modal-done:hover{
    background-color:#4D4D4D ;
    color: white;
    transition: 0.3s ease-in-out;
}
.ant-input-number-handler-wrap {
    visibility: visible !important;
    opacity: 1 !important;
    width: 22px !important;
  }
.keyword-modal-title{
    font-size: 20px;
    font-weight: 700;
    color: #1F1F1F;
    margin: 0;
}
.jd-modal{
    min-height: 80vh;
    max-height: 80vh;
  

}

.quill-editor{
    overflow-y: scroll;
    min-height: 75vh;
    max-height: 75vh;
}

.load-more{
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to bottom, transparent 0%, #fff 30%, #fff 100%);
    z-index: 100;
    height: 60px;
  
}
.load-more div{
    font-size:14px;
    font-weight: 700;
    width: 100%;
    height: 100%;
    gap: 10px;
    color: #1890FF;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}