.container{display: flex;
flex-direction: column;
height: 100%;
background-color:white;
position:absolute;
top:40px;
width: 100%;
overflow:auto;
}
.header2{
color: var(--Texts-title_neutral, #1F1F1F);
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.mainHeader{
    color: var(--Texts-title_neutral, #1F1F1F);

/* Title/20 - Bold */
font-family: Figtree;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.box{
    width: 90%;
    padding: 12px;
    border-radius: var(--Radius-XS, 4px);
    border: 1.5px solid var(--boarders-lines-neutral-light, #E9E9E9);
    background: var(--Backgrounds-white, #FFF);
}
.greyBox{
    display: flex;
    align-items:center;
    margin-left: 24px;
    padding: 16px;
    min-height: 80px;
    width: 90%;
    border-radius: var(--Radius-XS, 4px);
    background: var(--Backgrounds-light, #F5F5F5);
    
}
.radioGroup {
    display: flex;
    gap: 1rem;
  }
  
  .radioLabel {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    color: #333;
   
  }
  
  .radioInput {
    display: none; /* Hide the default radio input */
  }
  
  .radioCircle {
    width: 16px;
    height: 16px;
    border: 2px solid #E9E9E9;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 8px;
    transition: background-color 0.3s ease;
  }
  
  .radioInput:checked + .radioCircle {
    background-color: #1890ff;
    
  }
  
  .radioCircle::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .radioInput:checked + .radioCircle::after {
    opacity: 1;
  }
  .flexRow{
    display:flex;
    flex-direction:row;
    justify-content:space-between
  }
  .inputStyle{
    border-radius: var(--Radius-XS, 4px) 0px 0px var(--Radius-XS, 4px);
border: 1px solid var(--boarders-lines-neutral-subtle, #D0D0D0);
background: var(--Backgrounds-white, #FFF);
height: 30px;
width: 30%;
  }