.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    position: relative;
    min-width: 25rem;
    min-height: 22rem;
    max-width: 506px;
  }
  .experince-modal-title{
    text-align: left;
  }
.experience-cards>div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

}
.level-card{
  display: flex;
  flex-direction: row;
  width: max-content;
  border-radius: 4px;
  height: max-content;
  border: 1px solid #E9E9E9;
  padding: 16px;
  min-width: 180px;
  min-height: 65px;
}
.level-card div{
  display: flex;
  flex-direction:column;
  width: 100%;
  height: 100%;

}
.level-card div h3{
  font-size: 14px;
  font-weight: 700;
  color: #1F1F1F;
  margin: 2px 0px;
}
.level-card div p {
  font-size: 12px;
  font-weight: 600;
  color: #7A7A7A;
  margin: 2px 0px;
}

  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .progress-bar-container {
    width: 78%;
    height: 0.75rem;
    background-color: #E6F7FF;
    border-radius: 999px;
    overflow: hidden;
    margin-bottom: 1rem;
    justify-content: space-around;
    margin-left: 2.5rem;
    
 
  }
  
  .progress-bar {
    height: 100%;
    background-color: #1890FF;
    width: 0;
    transition: width 0.5s;
   border-radius: 999px;
  
  }
  .progress-bar-content{
    color: var(--Texts-link, #1890FF);
text-align: center;

/* Body/Body 03/14 - Semi Bold */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  
  .experience-cards{

  }

  .custom-level{
    display: flex;
    flex-direction: column!important;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
  .custom-level input{
    width: 100px;
    border:1.5px solid #E9E9E9;
    height: 35px;
    border-radius: 3px;
  }
