.share-jd,.more-jd{
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.share-jd svg,.more-jd svg{
    font-size: 18px;
    font-weight: 700;

}


.share-jd:hover svg, .more-jd:hover svg{
    color: black;
    transform: scale(1.2);
}
.delete-jd ,.delete-jd svg{
    color: #F5222D;
    font-size: 14px;
    font-weight: 600;
}

.export-jd, .export-jd svg{
    color: #1F1F1F;
    font-size: 14px;
    font-weight: 600;
}