.sidebar-container{
    display: flex;
    flex-direction: column;
    background-color: #0b113b;
   
    min-height: 500px;
    padding: 32px 16px;
    justify-content: space-between;

}
.sidebar-container > div:nth-child(1)  {
    /* Your custom styles */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    
}
.sidebar-container > div:nth-child(2)  {
    /* Your custom styles */
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.sidebar-container-expanded{
    display: flex;
    flex-direction: column;
    background-color: #0b113b;
    width: 252px;
    font-family: 'Roboto';
    padding: 32px 16px;
    position:absolute;
    left: 72px;
    top: 0;
    bottom: 0;
    z-index: 100000000000000000000;
    color: #ffffff;
}
.sidebar-container-expanded > h3:nth-child(1){
    color: #ffffff;
}
li {
    list-style-type: none;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    opacity: 0.6;
   
}
.navlink{
    text-decoration: none;
    outline: none;
    color: #ffffff;
}