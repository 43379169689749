.suggestions-list {
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    background: #fff;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  