/* General table header cell styling */

  

  .table-fixed-column-outter {
    position: relative;
    width: 100%; 
    height: max-content;

    display: flex;
    flex-direction: column;
  }
  
  .table-fixed-column-inner {

   overflow-y:hidden;
   
   overflow-x: scroll;
    padding-left:   200px;
   /* min-height: 70vh; */
   /* min-height: 90vh; */
  /* margin-left: 160px; */
   

  }


  .custom-table.table.table-fixed-column {
    table-layout: fixed;
    width: 100%;
    
  }
  
  .custom-table.table td,
  .custom-table.table th {
    width: 160px;
  }
  
  .custom-table.table th:first-child,
  .custom-table.table tr td:first-child {
    position: absolute;
    left: 0;
    min-width: 200px;
  
    z-index: 1000
  }
  .custom-table.table th:first-child{
    min-height: 51px !important;
  }



  .custom-table.table{
  border-collapse: collapse;
}
.custom-table thead {
    background-color: #f0f0f0;
  }
  
  .custom-table th,.custom-table td {
    padding: 12px 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .custom-table th {
    
    /* overflow: hidden; */
    color: var(--Texts-body, #4D4D4D);
    text-overflow: ellipsis;
    font-family: Figtree;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
    

  }
  
  .custom-table td {
    font-weight: normal;
    background-color: #ffffff;
    color: #161616;
  }

  
  .custom-table tr .table-headerCell{
    background-color: #E9E9E9;
    white-space: nowrap;   /* Prevent text wrapping */
    /* overflow-x: hidden;      */
    text-overflow: ellipsis;
    /* border-right: 2px solid #ddd;  */
    border: none;


  }
 
  .custom-table tr .table-headerCell::after{
    content: ""; /* Empty content to create the line */
    position: absolute;
    top: 50%; /* Center the border vertically */
    right: 0; /* Align to the right edge */
    transform: translateY(-50%); /* Adjust position to center vertically */
    height: 50%; /* Set the height for a shorter border */
    width: 2px; /* Adjust thickness as needed */
    background-color: #ddd;

  }
  .custom-table  .table-cell{
    max-width: max-content !important;
    min-width: 160px;
  min-height: 61px;
  /* padding: var(--S-3S, 16px); */
  align-items: center;
  gap: 15px;
  align-self: stretch;
  /* max-width: 100px; */
  white-space: nowrap;   /* Prevent text wrapping */
  overflow: hidden;      /* Hide text overflow */
  text-overflow: ellipsis;
  z-index: 3;
  color: #161616;
  place-content: center;
   }
   


    
  

   .custom-table .table-headerCell{
    position: relative;
}
  
  /* Dropdown container */
  .custom-table .table-dropdown {
    position: absolute;
    top: 30px; /* Adjust based on header height */
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 100%; /* Set width as per design */
    z-index: 1000000;
    border-radius: 5px;
    min-width: 160px;
  max-height: 260px;
  overflow-y: scroll;

  }
  
  /* Multi-select dropdown */
  .custom-table .multi-select-dropdown , .search-input{
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  
  .custom-table .search-input input[type="text"] {
    /* padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px; */
 
  
  }
  .custom-table input[type="checkbox"]{
    cursor: pointer;
  }
  
  .custom-table .multi-select-dropdown .options-list {
    max-height: 115px; 
     overflow-y: auto;
   position: relative;
    /* border: 1px solid #ddd; */
    padding: 5px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    align-items: flex-start;
  }
  
  /* Styling individual option items */
  .custom-table .multi-select-dropdown .options-list div {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }
  
  .custom-table .multi-select-dropdown .options-list label {
    margin-left: 8px;
    font-size: 14px;
    color: #333;
  }
  
  /* Single-select dropdown */
  .custom-table .single-select-dropdown {
    display: flex;
    flex-direction: column;
  }
  


  
  .custom-table .single-select-dropdown .options-list {
    display: flex;
    flex-direction: column;
    max-height: 150px;
    width: 100%;
    overflow-y: auto;
  }
  
  .custom-table .option-item {
    padding: 8px;
    
    cursor: pointer;
    /* border-bottom: 1px solid #ddd; */
  }
  
  .custom-table .option-item.selected {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .custom-table  .option-item:hover {
    background-color: #e0e0e0;
  }
  


  
  /* Buttons */

  .custom-table  .actions{
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .custom-table .actions button {
    padding: 8px 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 5px;
    width: 50%;
  }
  
  .custom-table .actions button:hover {
    /* background-color: #ddd; */
  }
  
  .custom-table  .actions button:last-of-type  {
    background-color: #007bff;
    color: white;
    margin-right: 5px;
  }
  
  .custom-table .actions button:last-of-type:hover {
    background-color: #0056b3;
  }
  
  .custom-table .actions button:first-of-type {
    background-color: transparent;
    color: #c8cacb;
  }
  
  
  /* Select All checkbox */
  .custom-table .multi-select-dropdown > div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .custom-table  .multi-select-dropdown label {
    margin-left: 8px;
    font-size: 14px;
  }
  
  /* Close dropdown on click outside */
  .custom-table .dropdown-close-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: transparent;
  }
  



  .no-table-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 400px;
    background: white;

    border-radius: 4px;
    margin-bottom: 20px;
  }
  .no-filter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 80vh;
    background: white;
   
    border-radius: 4px;
    margin-bottom: 20px;
  }
 
  .no-table-data img{
    width: 160px;
    height: 160px;

  }
   .no-table-data h3{
    margin: 2px 0px;
    font-size: 18px;
    color: #1F1F1F;
    font-weight: 700;
  }

   .no-table-data p{
    margin: 2px 0px;
    font-size: 14px;
    color: #4D4D4D;
    font-weight: 500;
  }
  