.button-container{
    display: flex;
    min-width: 156px;
    width: max-content;
    height: 37px;
    padding: var(--S-3S, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--XS-3XS, 8px);
    border-radius: var(--Radius-XS, 4px);
    background: var(--Buttons-Secondary-default, #1890FF);
    border: none;
    color: #ffffff;
    text-align: right;
/* Body/Body 03/Body 02/16 - Semi Bold */
font-family: Figtree;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
cursor: pointer;
}