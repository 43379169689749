/* Container for the entire component */
.experience-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }
  
  /* Label styling */
  .experience-label {
    font-size: 14px;
    color: #6b6b6b;
    margin-bottom: 8px;
  }
  
  /* Input container */
  .input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    width: 200px;
  }
  
  /* The input field */
  .experience-input {
    flex: 1;
    text-align: left;
    padding: 10px;
    border: none;
    font-size: 16px;
  }
  
  /* The control buttons for increment/decrement */
  .controls {
    display: flex;
    flex-direction: column;
  }
  
  .controls button {
    border: none;
    background-color: white;
    cursor: pointer;
    padding: 0 5px;
    font-size: 16px;
    border-left: 1px solid #ccc;
  }
  
  .controls button:active {
    background-color: #eee;
  }
  
  /* Separate styles for up and down arrows */
  .up-arrow {
    padding: 8px;
    border-bottom: 1px solid #ccc;
  }
  
  .down-arrow {
    padding: 8px;
  }
  