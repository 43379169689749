/* Container styling */
.filterdropdown-container {
    padding: 10px;
    border: 1px solid #ccc;
    width: 100%;
    background-color: #fff;
    z-index: 5;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Option styling */
  .filterdropdown-options {
    display: flex;
    align-items: center;
    
  }
  
  .filterdropdown-options label {
    font-size: 16px; /* Slightly larger font */
    color: #1F1F1F;
    
    cursor: pointer;
    font-weight: 500; /* Add more weight to match the style */
    display: flex;
    gap: 8px;
    align-items: center;
    align-self: stretch;
    padding: var(--XS-3XS, 8px) var(--S-S, 12px);
  }
  
  /* Custom Checkbox Styling */
  .filterdropdown-options input[type="checkbox"] {
    position: relative;
    width: 20px; /* Larger size */
    height: 20px;
    -webkit-appearance: none;
    appearance: none;
    border: 2px solid #4D4D4D; /* Darker border color */
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    /* transition: background-color 0.2s ease, border-color 0.2s ease; */
  }
  
  .filterdropdown-options input[type="checkbox"]:checked {
    background-color: #1890FF;
    /* border-color: #4D4D4D; */
  }
  
  .filterdropdown-options input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 6px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  
  /* Button Styling */
  .filterdropdown-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
  }
  
  .filterdropdown-buttons button {
    display: flex;
padding: var(--XS-2XS, 6px) var(--S-3S, 16px);
justify-content: center;
align-items: center;
gap: var(--XS-4XS, 10px);
border-radius: var(--Radius-XXS, 2px);
background: var(--Buttons-Secondary-default, #1890FF);
color: var(--Texts-negative, #FFF);
text-align: center;

/* Body/Body 03/14 - Semi Bold */
font-family: Figtree;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
border: none;

    
  }
  
  .filterdropdown-buttons button:hover {
    background-color: #555;
  }
  
  .filterdropdown-buttons button:first-child {
    background-color: #fff;
    color: #D0D0D0;
   
  }
  
  .filterdropdown-buttons button:first-child:hover {
    background-color: #e0e0e0;
  }
  